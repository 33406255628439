import React, { Component } from "react"

class BackgroundAnim extends Component {
  render() {
    return (
      <div>
        <ul className="circles">
          <li>
            {" "}
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
          <li>
            {" "}
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
          <li>
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
          <li>
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
          <li>
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
          <li>
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
          <li>
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
          <li>
            {" "}
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
          <li>
            {" "}
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
          <li>
            {" "}
            <img
              src="https://res.cloudinary.com/dsdd1sdbk/image/upload/v1589228345/watercolorflower_orov1l.png"
              alt="watercolor pink flower"
            ></img>
          </li>
        </ul>
      </div>
    )
  }
}
export default BackgroundAnim
